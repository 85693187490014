'use client'
import React from 'react';
import cl from './SimpleWhiteButton.module.css'
import cn from 'classnames';

const SimpleWhiteButton = ({ title, onClick, disabled, className, children, btnRef, ...props }) => {
    return (
        <button ref={btnRef} title={title} className={cn(cl.simpleWhite, className)} onClick={e => { e.stopPropagation(); onClick() }} disabled={disabled}  {...props}>{children ?? title}</button>
    );
};

export default SimpleWhiteButton;