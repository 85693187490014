'use client'
import React, { useEffect, useState } from 'react';
import cl from './RadioBtnSex.module.css';
import { BtnSexForm, Radio } from './RadioBtnSexStyled';

const RadioBtnSex = ({ set, value, validateSet, name, onChange }) => {

    const [state, setState] = useState('');
    const [old, setOld] = useState('')

    useEffect(() => {
        setOld(value)
        setState(value)
    }, [value])

    const handleOptionChange = (e) => {
        setState(e.target.value)
        set(e.target.value)
    }

    return (
        <BtnSexForm>
            <Radio>
                <label>
                    <input
                        type="radio"
                        value='М'
                        checked={value == 'М'}
                        name={name ?? 'sex'}
                        onChange={e => onChange(e)}
                        {...validateSet} />
                    <span>М</span>
                </label>
            </Radio>
            <Radio>
                <label>
                    <input
                        type="radio"
                        value='Ж'
                        checked={value == 'Ж'}
                        name={name ?? 'sex'}
                        onChange={e => onChange(e)}
                        {...validateSet} />
                    <span>Ж</span>
                </label>
            </Radio>
        </BtnSexForm>
    );
};

export default RadioBtnSex;