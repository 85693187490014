import React from 'react';
import cl from './buttonNo.module.css'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FaXmark } from "react-icons/fa6";

const ButtonNo = (props) => {
    return (
        <button className={cl.buttonNo} onClick={props.onClick}>
            {/* <FontAwesomeIcon icon={icon({name: 'xmark'})} className='icon' /> */}
            <FaXmark />
        </button>
    );
};

export default ButtonNo;