"use client"
import styled from 'styled-components';

export const SwitchButton = styled.button`
border: none;
flex: 1;
padding: 14px 0;
border-radius: 14px;
font-weight: 600;
color: #163C85;
background: ${props => props.$active ? '#A7C5FD' : '#E8F1FF'};
justify-content:  ${props => props.center ? 'center' : 'flex-start'};
text-align: ${props => props.textalign};
`



// ? (props.active === props.id )? '#A7C5FD' :