'use client'
import React, { Suspense, useEffect, useState } from 'react';
// import { observer } from 'mobx-react-lite';
import { DefaultWrapper } from '@shared/UI/Wrappers';
import EmailRecovey from '@src/widgets/Autorisation/EmailRecovey/EmailRecovey';
import AuthorisationWidget from '@src/widgets/Autorisation/AuthorisationWidget/AuthorisationWidget';
import { PageLoginWrap } from './ui';
// import userStore from '@shared/store/userStore';

const PageLogin = ({ serverShowForm}) => {  
    const [rememberPassword, setRememberPassword] = useState(false)
    return (
        <PageLoginWrap>
            <DefaultWrapper style={{ padding: '32px', width: '376px', borderRadius: '30px' }}>
                {rememberPassword == false
                    ?
                    <Suspense>
                        <AuthorisationWidget serverShowForm = {serverShowForm } callRememberPassword={() => setRememberPassword(true)} />
                    </Suspense>
                    :
                    <EmailRecovey cancelAction={() => setRememberPassword(false)} />
                }
            </DefaultWrapper>
        </PageLoginWrap >
    );
};

export default PageLogin;