import styled from "styled-components";

export const BtnFixedPosition = styled.div`
    width: 50px;
    top: 150px;
    height: 50px;
    left: 20px;
    background: #fff;
    border: 1px solid #ededed;
    border-radius: 50%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 29px;
    cursor: pointer
`