import React from 'react';
import cl from './deleteButton.module.css'
import Cookies from 'universal-cookie';
import { host } from '../../../../app/globals';

const DeleteButton = (props) => {
    const remove = (deleteList, removeId, url) => {
        props.setDelete(deleteList.filter(t => t.id !== removeId))
        var cookies = new Cookies
        var data = {
            user_id: cookies.get('userId'),
            device_token: cookies.get('device_token'),
            elements: [removeId]
        }
        fetch(host + url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(
                (result) => {
                },
            )
    }
    return (
        <button className={cl.deleteButton} onClick={() => remove(props.deleteList, props.removeId, props.url)}>удалить</button>
    );
};

export default DeleteButton;