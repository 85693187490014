import React from 'react';
import { Link } from 'react-router-dom';

import cl from './accountMenuButton.module.css'

const AccountMenuButton = (props) => {

    return (
        <Link className={cl.accountMenuButton} onClick={props.onClick} to={props.to}>
            {
                props.icon
                    ? <div className={cl.icon}>
                        {props.icon.name}
                    </div>
                    : null
            }
            {props.title}
        </Link>
    );
};

export default AccountMenuButton;