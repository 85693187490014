'use client'
import styled from "styled-components";
import { ColorBtnSample } from "./ColorBtnSampleStyled";


export const GreenButton = styled(ColorBtnSample)`
    background-color: #ADEFBA;
    color: #309D63;
    &:hover { 
        color: #388B7C;
        background-color: var(--color-green-hover);
    }
`