import React from 'react';
import { SwitchAuthorize } from './ui';
import { SwitchButton } from '@shared/UI/Buttons/SwitchButton/SwitchButton.styled';

const SystemEnterSwitch = ({ SwitchAction, activeForm }) => {
    return (
        <SwitchAuthorize>
            <SwitchButton
                id={'auth'}
                $active={activeForm === 'auth'}
                type={'button'}
                onClick={(e) => { SwitchAction(e) }}>
                <p>Вход</p>
            </SwitchButton>
            <SwitchButton
                id={'reg'}
                $active={activeForm === 'reg'}
                type={'button'}
                onClick={(e) => { SwitchAction(e) }}>
                <p>Регистрация</p>
            </SwitchButton>
        </SwitchAuthorize>
    );
};

export default SystemEnterSwitch;