import React from 'react';
import cn from 'classnames'
import cl from './ButtonIcon.module.css'
import { Loader } from '@shared/UI/Loaders';

const ButtonIcon = ({ icon, onClick, disabled = false, sending, type, children, className }) => {

    return (
        <button className={cn(cl.buttonIcon, className)} type={type} onClick={onClick} disabled={disabled}>
            {
                (icon) ?
                    (!sending)
                        ? <span>{icon}</span>
                        : <Loader />
                    : null
            }
            {children}
        </button>
    );
};

export default ButtonIcon;