'use client'
import React from 'react';
import cl from './ui.module.css'
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { Loader } from '@shared/UI/Loaders';
import Image from 'next/image'


const SendMailConfirm = ({ SendData, isSendConfirm, SendAction, HasDepart, seconds }) => {
    return (
        <div className={cl.vertical}>
            <div className={cl.horizontal}>
                <button type='button'
                    onClick={() => SendAction()}
                >
                    <FaArrowLeftLong style={{ color: 'inherit' }} />
                </button>
                <NavLink className={cl.logo} to="/">
                    <img src={window.location.origin + '/images/logo.svg'} alt='logo' />
                </NavLink>
            </div>
            <p>Ваша почта не подтверждена. Отправить ссылку для подтверждения на почту?</p>
            <div className={cn(cl.horizontal, cl._center)}>
                <button
                    className={cl.sendButton}
                    type='button'
                    disabled={isSendConfirm}
                    onClick={() => SendData()}
                >
                    {
                        !HasDepart
                            ?
                            isSendConfirm
                                ? 'Отправлено'
                                : 'Отправить'
                            : <Loader />
                    }
                </button>
                <p style={isSendConfirm ? { color: 'red' } : null}>
                    {isSendConfirm ? 'Ожидайте' : null} {seconds} сек.
                </p>
            </div>
        </div>
    );
};

export default SendMailConfirm;