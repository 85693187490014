import React from 'react';
import cl from './buttonYes.module.css'
// 
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FaCheck } from "react-icons/fa";

const ButtonYes = (props) => {
    return (
        <button className={cl.buttonYes} onClick={props.onClick}>
            <FaCheck />
            {/* <FontAwesomeIcon icon={icon({name: 'check'})} className='icon' /> */}
        </button>
    );
};

export default ButtonYes;