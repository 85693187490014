'use client' 
import { useRouter } from 'next/navigation'
import React from 'react';
import cl from './BackButton.module.css';
import cn from 'classnames'

const BackButton = ({ className, additionalText }) => {
    const navigate = useRouter()
    const goBack = () => navigate.back()
    return (
        <button onClick={goBack} type={'button'} className={cn(cl.backButton, className)}>
            <span> {additionalText ? additionalText : null} Назад</span>
        </button>
    );
};

export default BackButton;