import React from 'react';
import cl from './AddItem.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FaPlus } from "react-icons/fa6";

const AddItem = (props) => {
    return (
        <button className={[cl.addItem, 'default-style'].join(' ')} onClick={() => props.setVisiblePopup(!props.visiblePopup)}>
            {props.icon ?
            //  <FontAwesomeIcon icon={solid('plus')} className='icon'/> 
             <FaPlus/>
             : null}
                {props.title}
        </button>
    );
};

export default AddItem;