import styled from "styled-components";

export const BtnSexForm = styled.form`
        display: flex;
        gap: 8px;
`

export const Radio = styled.div`
    input[type=radio] {
    overflow: hidden;
    display: grid;
    place-content: center;
    -webkit-appearance: none;
    appearance: none;
    background-color: hsl(0, 0%, 82%);
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 3em;
    height: 3em;
    border-radius: 30%;
    cursor: pointer;
}

 input[type="radio"]:checked {
    background-color: #246ffb;
    transition: background-color 0.2s;
}

 input[type="radio"]:checked+span {
    color: #fff;
}

 label {
    position: relative;
}

 span {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
}
`
