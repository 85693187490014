import React from 'react';
import cl from './FullscreenPopup.module.css'
import cn from 'classnames'
import { TfiClose } from 'react-icons/tfi'

const ClosePopupBtn = ({ className, onClick, icon = <TfiClose /> }) => {
    return (
        <button className={cn(cl.closePopupBtn, className)} onClick={onClick}>{icon}</button>
    );
};

export default ClosePopupBtn;