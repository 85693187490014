import { AcceptButton } from "@shared/UI/Buttons";
import styled from "styled-components";
export const MailRecoveryForm = styled.div`
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #163C85;
    font-weight: 600;
`
export const EntryArea = styled.div`
   width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
`

