import React from 'react';
import cl from './FullscreenPopup.module.css'
import ClosePopupBtn from './ClosePopupBtn';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { FaXmark } from 'react-icons/fa6';

const FullscreenPopup = ({ children, visible, setVisible, title, confirmButtons = true, addNewItem, refInput, className }) => {

    const rootClasses = [cl.myModal]

    if (visible) {
        rootClasses.push(cl.active)
    }

    const cancel = () => {
        setVisible(false);
        refInput.current.value = "";
    }

    const save = () => {
        addNewItem();
        refInput.current.value = "";
        setVisible(false);
    }

    return (
        <div className={cn(rootClasses, className )}>

            <ClosePopupBtn onClick={cancel} icon={
                <FaXmark />
                // <FontAwesomeIcon icon={solid('xmark')} className='icon' />
                } />
            {children}
        </div>
    );
};

export default FullscreenPopup;