import React from 'react';
import cl from './ErrorWS.module.css'
import { Loader } from '@shared/UI/Loaders';

const ErrorWS = () => {
    return (
        <div className={cl.wrap}>
            <Loader/>
            <h4>Подключение разорвано</h4>
        </div>
    );
};

export default ErrorWS;