const app = 'dev';

var url;
var ws_url;

if (app === 'dev') {
    url = 'http://127.0.0.1:5000';
    ws_url = 'ws://127.0.0.1:5000/ws';

} else
    if (app === 'prod') {
        url = 'https://api.totera.ru';
        ws_url = 'wss://api.totera.ru/ws';
    }

export const host = url;

// export const vks = 'https://vks.totera.ru'

export const link_project_docs = {
    policy_pdn: process.env.REACT_APP_POLICY_PDN,
    descript_function: process.env.REACT_APP_DESCRIPTION_FUNCTION,
    install_instruction: process.env.REACT_APP_INSTALL_INSTRUCTION,
    user_guide: process.env.REACT_APP_USER_GUIDE,
    contract_offer: process.env.REACT_APP_CONTRACT_OFFER
}

export const hostNext = process.env.NEXT_PUBLIC_REACT_APP_NEXT

export const hostApi = process.env.NEXT_PUBLIC_REACT_APP_API

// export const clearBack = process.env.NEXT_PUBLIC_REACT_CLEAR_BACK


export const vks = process.env.REACT_APP_VKS_URL

export const ws = process.env.REACT_APP_WS_URL;

export const projectName = process.env.NEXT_PUBLIC_REACT_PROJECT_NAME;

export const baseProjectUrl = process.env.NEXT_PUBLIC_REACT_BASE_URL;


