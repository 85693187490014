'use client'
import React, { useEffect, useState } from 'react';
import cl from './PageLogin.module.css'
import { AcceptButton } from '@shared/UI/Buttons';
import { ErrorField } from '@shared/UI/Errors';
import useDeviceInfomation from '@shared/hooks/AuthHooks';
import { observer } from 'mobx-react-lite';
import userStore from '@shared/store/userStore';
import { api } from '@src/app/api';
import { usePassEncrypt, useSetParamsUrl } from '@shared/hooks';
import { LoaderBlueFullView } from '@shared/UI/Loaders';
import { useForm } from 'react-hook-form';
import { InputValidateUseForm } from '@shared/UI/Inputs/HookForm';
import OneSwitchCheckBoxValidate from '@shared/UI/Inputs/HookForm/OneSwitchCheckBoxValidate';
import { link_project_docs } from '@src/app/globals/server';
import SystemEnterSwitch from '@features/SystemEnterSwitch/SystemEnterSwitch';
import SendMailConfirm from '@widgets/Autorisation/SendMailConfirm/SendMailConfirm';
import { useRouter, useSearchParams, usePathname } from 'next/navigation'
import Link from 'next/link';
import { invite_group } from '@src/app/globals/cookie'; 
import Cookies from 'universal-cookie';
import { HocInviteRoute } from '@shared/hoc';

const AuthorisationWidget = observer(({ callRememberPassword, serverShowForm  }) => {
    const [showForm, setShowForm] = useState(serverShowForm)
    const [errorResult, setErrorResult] = useState()
    const [isAuth, setIsAuth] = useState(false)
    const [seconds, setSeconds] = useState(30)
    const [isSendConfirm, setIsSendConfirm] = useState(false)
    const [hasDepart, setHasDepart] = useState(false)
    // const [searchParams, setSearchParams] = useState()
    // const sp = useSearchParams()
    // const sel = useSearchParams()

    const [selectedParametrs, setParametrs] = useState([]);
    const router = useRouter()
    //const location =  useLocation()
    const location = useRouter()
    const pathname = usePathname();
    const searchParams = useSearchParams()

    const redirect = (url) => {
        router.push(url)
    }

    const [createUrl, updateUrl] = useSetParamsUrl()

    const { register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'onChange' });

    const goHome = () => redirect('/about')
    const device = useDeviceInfomation()
    const passEncrypt = usePassEncrypt()

    function updateSearchParam(key, value) {
        const params = new URLSearchParams(searchParams);
        if (value) {
            // set the search parameter if value is not empty
            params.set(key, value)
        } else {
            params.delete(key)
        }
        return `${pathname}?${params.toString()}`
    }
    const cookies = new Cookies()

    useEffect(() => {
        const isBanned = searchParams.get('isbanned') 
        if (isBanned) {
            setErrorResult({ code: 423, message: 'Ваш профиль заблокирован' })
           router.replace(updateSearchParam('isbanned'))
            updateUrl('v', false, true)
        }
      
    }, [])

    useEffect(() => {
        if (location.state?.error) {
            return setErrorResult({ ...location.state.error })
        }
        if (location.state?.showForm) {
            return setShowForm(location.state.showForm)
        }
        if (location.state?.change === 'success') {
            setErrorResult({
                code: 0,
                message: location.state.message
            })
        }
    }, [])

    useEffect(() => {
        if (seconds > 0 && isSendConfirm) {
            const timer = setInterval(() => setSeconds(seconds - 1), 1000)
            return () => clearInterval(timer)
        } else if (seconds === 0 && isSendConfirm) {
            setIsSendConfirm(false)
            setSeconds(30)
        }
    }, [seconds, isSendConfirm])

    function SendData(data) {
        setErrorResult()
        if (showForm === 'auth') {
            setIsAuth(true)
            userStore.logIn({
                mail: data.mail,
                password: passEncrypt(data.password),
                device_type_id: device.type,
                platform: '123',
                geo: device.geo == {} ? "" : JSON.stringify(device.geo),
                system: device.system,
            })
                .then(() => {
                    if (cookies.get('invite_group') !== undefined) {
                        router.push('/invite/group')
                    } else {
                        goHome()
                    }
                })
                .catch(error => {
                    if (error?.response?.status === 403 || error?.response?.status === 429) {
                        setShowForm('sendMail')
                        if (error.response.status === 429) {
                            const time = error.response.data
                            const second = Math.round(Number(time.split(':')[2]))
                            setSeconds(seconds - second)
                            setIsSendConfirm(true)
                        }
                        return setIsAuth(false)
                    }
                    if (error?.response?.status === 404) {
                        setErrorResult({...errorResult, message: "Ошибка запроса"})
                    }
                    if (error?.response?.status === 401) {
                        setErrorResult({ ...errorResult, message: "Логин или пароль введён неправильно" })
                    }
                    else {
                        setErrorResult({ ...errorResult, message: error?.response?.data })
                    }
                    setIsAuth(false)
                })
        } else if (showForm === 'reg') {
            setIsAuth(true)
            api.post(`user/registration`, {
                id_employment_type: "1",
                mail: data.mail,
                user_name: data.user_name,
                password: passEncrypt(data.password),
                user_confirm_policy: JSON.parse(data.user_confirm_policy),
            },
                {
                    params: {
                        link: invite_group()
                    }
                }
            )
                .then(result => {
                    setIsAuth(false)

                    setErrorResult({ ...errorResult, message: 'Успешно. Вам на почту выслано письмо для подтверждения', code: 0 })
                })
                .catch(error => {
                    setIsAuth(false)
                    setErrorResult({ ...errorResult, message: error.response.data })
                })
        } else if (showForm === 'sendMail') {
            setHasDepart(true)
            // api.post('user/ResetConfirmation', { mail: data })
            //     .then(() => {
            //         setHasDepart(false)
            //         setIsSendConfirm(true)
            //     })
            api.post(`user/retryregconfirmstring/${location.state.link}`, { mail: data })
                .then(() => {
                    setHasDepart(false)
                    setIsSendConfirm(true)
                })
        }
    }
    return (
        <form className={cl.form} onSubmit={handleSubmit(SendData)} >
            {
                !isAuth && showForm !== 'sendMail'
                    ?
                    <>
                        <ErrorField
                            confirm={errorResult?.code}
                            error={errorResult?.message}
                        />
                        <SystemEnterSwitch
                            SwitchAction={(e) => { setShowForm(e.currentTarget.id); setErrorResult() }}
                            activeForm={showForm}
                        />
                        {
                            showForm === 'reg'
                                ?
                                <InputValidateUseForm
                                    title={'Имя'}
                                    type={'text'}
                                    error={errors?.user_name}
                                    validateSet={register("user_name", {
                                        required: "Пожалуйста введите Имя.",
                                        minLength: {
                                            value: 2,
                                            message: 'минимум 2 символа'
                                        },
                                        pattern: {
                                            value: /[А-Я][а-яё]/,
                                            message: 'Ведите имя с большой буквы',
                                        }
                                    })}
                                />
                                : null
                        }

                        {/* ----------------------Вход---------------------------- */}
                        <InputValidateUseForm
                            title={'Электронная почта'}
                            type={'email'}
                            error={errors?.mail}
                            validateSet={register("mail", {
                                required: "Пожалуйста, введите e-mail.",
                                minLength: {
                                    value: 1,
                                    message: 'минимум 1 символа',
                                },
                                pattern: {
                                    value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                                    message: 'неверный формат email',
                                }
                            })}
                        />
                        <InputValidateUseForm
                            title={'Пароль'}
                            type={'password'}
                            error={errors?.password}
                            validateSet={register("password", {
                                required: "Пожалуйста, введите пароль.",
                            })}
                        />
                        {/* -----------------------Вход--------------------------- */}
                        {
                            showForm === 'reg'
                                ?
                                <OneSwitchCheckBoxValidate
                                    RadioClass={cl.policy}
                                    onChange={(e) => setValue('user_confirm_policy', !watch('user_confirm_policy'))}
                                    RadioList={
                                        {
                                            id: 'policy',
                                            value: watch('user_confirm_policy'),
                                            name: 'user_confirm_policy',
                                            title:
                                                <p>Ознакомлен с
                                                    <a href={link_project_docs.policy_pdn} download> политикой обработки персональных данных</a> и <a href={link_project_docs.user_policy} target={'_blank'} download>пользовательским соглашением</a>
                                                </p>
                                        }
                                    }
                                    error={errors?.user_confirm_policy}
                                    validateSet={{ ...register("user_confirm_policy", { required: "Не заполнено" }) }}
                                />
                                : null
                        }
                        <div className={cl.entryArea}>
                            <AcceptButton
                                type={'submit'}
                                title={showForm === 'auth' ? 'Войти' : 'Зарегистрироваться'}
                            />
                            {
                                showForm === 'auth' &&
                                <div className={cl.forgotPassword}>
                                    <button onClick={() => callRememberPassword(true)}>Забыли пароль?</button>
                                </div>
                            }
                            {
                                showForm === 'reg'
                                    ? <Link href={'/regteacher'} className={cl.teacherReg}>Стать автором</Link>
                                    : null
                            }
                        </div>
                    </>
                    : showForm === 'sendMail'
                        ?
                        <SendMailConfirm
                            isSendConfirm={isSendConfirm}
                            SendData={() => SendData(watch('mail'))}
                            SendAction={() => { setShowForm('auth'); setSeconds(30); setHasDepart(false) }}
                            HasDepart={hasDepart}
                            seconds={seconds}
                        />
                        : <LoaderBlueFullView />
            }
        </form>
    );
});

export default AuthorisationWidget;