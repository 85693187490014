'use client'
import React, { useState, useEffect, useContext } from 'react';

const MyRadioBtn = (props) => {

    const [List, setList] = useState(props.List) 
    return (
        <div>
            {List.map((elment) => (
                <>
                <input key = {elment.id} type="radio" name={props.setname} id={'el_' + elment.id} defaultValue={elment.id} onChange={props.onchange} {...props.validateSet}/>     
                </>                
                ))
            }
        </div>
    );
};

export default MyRadioBtn;
