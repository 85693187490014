'use client'
import { api } from '@src/app/api';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AcceptButton } from '@shared/UI/Buttons';
import { InputValidateUseForm } from '@shared/UI/Inputs/HookForm';
import { EntryArea, MailRecoveryForm } from './ui';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { MainPopup } from '@shared/UI/Popups';
import { Loader } from '@shared/UI/Loaders';

const EmailRecovery = ({ cancelAction }) => {
    const [dispatchNotice, setDispatchNotice] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const { register,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors }
    } = useForm({ mode: 'onChange' });

    useEffect(() => {

    }, [])

    const SendResetQuerry = (mail) => {
        api.post(`/user/PasswordRecovery/${watch('recoveryEmail')}`)
    }

    return (
        <MailRecoveryForm>
            <p>Укажите электронную почту для восстановления пароля</p>
            <InputValidateUseForm
                title={'Электронная почта'}
                type={'recoveryEmail'}
                error={errors?.recoveryEmail}
                validateSet={register("recoveryEmail", {
                    required: "Пожалуйста, введите e-mail.",
                    minLength: {
                        value: 1,
                        message: 'минимум 1 символа',
                    },
                    pattern: {
                        value: /^(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#%&*\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{2,63}\.)+[a-zA-Z]{2,15}$/,
                        message: 'неверный формат email',
                    }
                })}
            />
            <EntryArea>
                <AcceptButton
                    type='button'
                    title='Отправить письмо'
                    onClick={() => { SendResetQuerry() }}
                />
            </EntryArea>
            <p onClick={() => cancelAction(faLessThanEqual)} >назад</p>
        </MailRecoveryForm>
    );
};

export default EmailRecovery;